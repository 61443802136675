<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>Perfil</h1>
        </div>
        <!-- boxapp body -->
        <div class="box-main">
          <div class="section">
            <!-- section head -->
            <div class="head mt-0">
              <h4>Dados Cadastrais</h4>
            </div>
            <!-- conteudo -->
            <div class="form-grid">
              <v-layout row wrap>
                <v-flex
                  class="upload-box-profile-father"
                  style="width: 300px !important"
                  shrink
                  pr-3
                >
                  <FileImageUpload
                    :maxWidth="1000"
                    :maxHeight="1000"
                    :quality="0.7"
                    :file="council.file"
                    unselectedText="Logo do Conselho"
                  />
                </v-flex>
                <v-flex form-grid xs12 md12 lg8 xl9>
                  <v-layout row wrap mw-total>
                    <v-flex xs12>
                      <InputSelect
                        ref="incentiveLawListInput"
                        textLabel="Lei de Incentivo"
                        valueAttribute="id"
                        textAttribute="name"
                        :items="IncentiveLawsText.filter(item => item.id == IncentiveLaws.EstatutoDeCriancaEAdolescente || item.id == IncentiveLaws.EstatutoDoIdoso )"
                        invalidInputMessage="Preencha a lei de incentivo"
                        v-model="council.incentiveLawId"
                      ></InputSelect>
                    </v-flex>
                    <v-flex xs12 sm6>
                      <InputText
                        ref="councilNameInput"
                        v-model="council.entity.name"
                        type="text"
                        place-holder="Nome do Conselho"
                        textLabel="Nome do Conselho"
                        invalidInputMessage="Preencha o nome do Conselho"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <InputText
                        ref="cnpjInput"
                        v-model="council.entity.cnpj"
                        type="tel"
                        @input="verifyCNPJ"
                        place-holder="CNPJ do Conselho"
                        textLabel="CNPJ do Conselho"
                        invalidInputMessage="Preencha o CNPJ do Conselho"
                        mask="##.###.###/####-##"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <InputText
                        ref="socialReasonInput"
                        v-model="council.entity.socialReason"
                        type="text"
                        place-holder="Razão Social"
                        textLabel="Razão Social do Conselho"
                        invalidInputMessage="Preencha a razão social do Conselho"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <BorderSeparation />
              <v-layout row wrap>
                <template
                  v-if="council.incentiveLawId == IncentiveLaws.EstatutoDeCriancaEAdolescente"
                >
                  <v-flex xs12 sm6>
                    <InputText
                      ref="fundNameInput"
                      v-model="council.childrenFund.name"
                      type="text"
                      place-holder="Nome do Fundo"
                      textLabel="Nome do Fundo"
                      invalidInputMessage="Preencha o nome do Fundo"
                      :required="!hasPermission([permissions.CRUDCouncil])"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <InputText
                      ref="childrenFundCNPJInput"
                      v-model="council.childrenFund.cnpj"
                      type="tel"
                      place-holder="CNPJ do Fundo"
                      textLabel="CNPJ do Fundo"
                      invalidInputMessage="Preencha o CNPJ do Fundo"
                      mask="##.###.###/####-##"
                      :required="!hasPermission([permissions.CRUDCouncil])"
                    />
                  </v-flex>
                </template>
                <template v-else>
                  <v-flex xs12 sm6>
                    <InputText
                      ref="fundNameInput"
                      v-model="council.elderlyFund.name"
                      type="text"
                      place-holder="Nome do Fundo"
                      textLabel="Nome do Fundo"
                      invalidInputMessage="Preencha o nome do Fundo"
                      :required="!hasPermission([permissions.CRUDCouncil])"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <InputText
                      ref="elderlyFundCNPJInput"
                      v-model="council.elderlyFund.cnpj"
                      type="tel"
                      place-holder="CNPJ do Fundo"
                      textLabel="CNPJ do Fundo"
                      invalidInputMessage="Preencha o CNPJ do Fundo"
                      mask="##.###.###/####-##"
                      :required="!hasPermission([permissions.CRUDCouncil])"
                    />
                  </v-flex>
                </template>
                <v-flex sm4 xs12 pr-2>
                  <InputSelect
                    ref="bankNumberInput"
                    v-model="council.bankData.bankId"
                    :items="bankList"
                    textLabel="Banco"
                    valueAttribute="id"
                    textAttribute="name"
                    invalidInputMessage="Selecione o banco"
                    :required="!hasPermission([permissions.CRUDCouncil])"
                  ></InputSelect>
                </v-flex> 
                <v-flex sm4 xs12 pr-2>
                  <InputText
                    ref="agencyInput"
                    v-model="council.bankData.agency"
                    type="text"
                    place-holder="Nº agência"
                    textLabel="Agência"
                    invalidInputMessage="Preencha a agência"
                    :validation="validateAgency"
                    :mask="['####', '####-X']"
                    :required="!hasPermission([permissions.CRUDCouncil])"
                  />
                </v-flex>
                <v-flex sm3 xs12 pr-2>
                  <InputText
                    ref="accountInput"
                    v-model="account"
                    type="text"
                    place-holder="Nº da conta"
                    textLabel="Conta Corrente"
                    invalidInputMessage="Preencha a conta corrente"
                    :validation="validateAccount"
                    :required="!hasPermission([permissions.CRUDCouncil])"
                  />
                </v-flex>
                <v-flex sm1 xs12>
                  <InputText
                    ref="digitInput"
                    v-model="digit"
                    type="text"
                    place-holder="Dígito da conta"
                    textLabel="Dígito"
                    invalidInputMessage="Preencha um dígito"
                    :validation="validateDigit"
                    :required="!hasPermission([permissions.CRUDCouncil])"
                  />
                </v-flex>
              </v-layout>
              <BorderSeparation />
              <v-layout row>
                <v-flex xs12 md6>
                  <InputText
                    ref="postalCodeInput"
                    v-model="council.entity.address.postalCode"
                    type="tel"
                    textLabel="CEP"
                    place-holder="CEP"
                    :showLoader="loaderInput"
                    invalidInputMessage="Preencha o CEP da instituição"
                    :required="!hasPermission([permissions.CRUDCouncil])"
                    mask="#####-###"
                    @input="searchCEP()"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <InputText
                    ref="streetInput"
                    v-model="council.entity.address.street"
                    type="text"
                    textLabel="Endereço"
                    place-holder="Endereço"
                    invalidInputMessage="Preencha o endereço da instituição"
                    :required="!hasPermission([permissions.CRUDCouncil])"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <InputText
                    ref="numberInput"
                    v-model="council.entity.address.number"
                    type="number"
                    textLabel="Número"
                    place-holder="Número"
                    :required="false"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <InputText
                    ref="complementInput"
                    v-model="council.entity.address.complement"
                    type="text"
                    textLabel="Complemento"
                    place-holder="Complemento"
                    :required="false"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <InputText
                    ref="neighborhoodInput"
                    v-model="council.entity.address.neighborhood"
                    type="text"
                    textLabel="Bairro"
                    place-holder="Bairro"
                    invalidInputMessage="Preencha o bairro da instituição"
                    :required="!hasPermission([permissions.CRUDCouncil])"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <InputText
                    ref="cityInput"
                    v-model="council.entity.address.city"
                    type="text"
                    textLabel="Cidade"
                    place-holder="Cidade"
                    invalidInputMessage="Preencha a cidade do conselho"
                    :disabled="true"
                    :required="!hasPermission([permissions.CRUDCouncil])"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <InputSelect
                    ref="stateInput"
                    :items="stateList"
                    v-model="council.entity.address.state"
                    textLabel="Estado"
                    valueAttribute="name"
                    textAttribute="name"
                    invalidInputMessage="Preencha o estado do conselho"
                    :disabled="true"
                    :required="!hasPermission([permissions.CRUDCouncil])"
                  ></InputSelect>
                </v-flex>
              </v-layout>
            </div>
          </div>
        </div>
      </div>
      <div class="box-help-spacer"></div>
      <HelpBox :text="helpBoxText" />
    </v-layout>
    <div class="footer-step">
      <v-btn
        flat
        round
        color="white"
        class="btn-primary flex-inherit px-5 ml-auto"
        large
        @click="save"
      >Concluir</v-btn>
    </div>
  </div>
</template>

<script type="plain/text">
import HelpBox from "@/components/HelpBox.vue";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";

import Council from "@/scripts/models/council.model";
import Entity from "@/scripts/models/entity.model";
import File from "@/scripts/models/file.model";
import Address from "@/scripts/models/address.model";
import BankData from "@/scripts/models/bankData.model";
import ChildrenFund from "@/scripts/models/childrenFund.model";
import ElderlyFund from "@/scripts/models/elderlyFund.model";

import CouncilService from "@/scripts/services/council.service";
import EntityService from "@/scripts/services/entity.service";
import FileService from "@/scripts/services/file.service";
import ProjectService from "@/scripts/services/project.service";
import LocationService from "@/scripts/services/location.service";

import Permissions from "@/scripts/models/enums/permissions.enum";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum";
import { Routes } from "@/scripts/models/enums/routes.enum";
import {
  IncentiveLaws,
  IncentiveLawsText
} from "@/scripts/models/enums/incentiveLaws.enum";
import { EntityType } from "@/scripts/models/enums/entityType.enum";

import CommonHelper from "@/scripts/helpers/common.helper";

import store from "@/store";
import { mapGetters } from "vuex";

export default {
  extends: ValidatableComponent,
  components: {
    HelpBox
  },
  props: ["pageOptions"],
  data() {
    return {
      permissions: Permissions,
      IncentiveLaws: IncentiveLaws,
      IncentiveLawsText: IncentiveLawsText,
      councilService: new CouncilService(),
      projectService: new ProjectService(),
      council: new Council(),
      fileService: new FileService(),
      locationService: new LocationService(),
      entityService: new EntityService(),
      cnpjUnique: true,
      stateList: [],
      bankList: [],
      account: "",
      digit: "",
      helpBoxText: HelpBoxText.CouncilProfile,
      selectedDocument: null,
      loaderInput: false,
      cepValidation: true,
      breadcrumbLevels: [
        { text: "Início" },
        { text: "Conselho" },
        { text: "Perfil" }
      ]
    };
  },
  beforeRouteEnter: (to, from, next) => {
    let hasPermission = false;
    if (to.query.councilId && to.query.councilId != 0) {
      if (
        store.getters.hasPermission(
          [Permissions.UpdateCouncil],
          to.query.entityId
        ) ||
        store.getters.hasPermission([Permissions.CRUDCouncil])
      ) {
        hasPermission = true;
      } else {
        hasPermission = false;
      }
    } else {
      if (store.getters.hasPermission([Permissions.CRUDCouncil])) {
        hasPermission = true;
      } else {
        hasPermission = false;
      }
    }
    if (hasPermission) next();
    else {
      store.commit(
        "SET_SNACKBAR_MESSAGE",
        "Você não tem permissão para acessar essa página."
      );
      next(false);
    }
  },
  computed: {
    ...mapGetters(["hasPermission"])
  },
  watch: {
    "council.incentiveLawId": {
      handler: function(after, before) {
        if (after == IncentiveLaws.EstatutoDeCriancaEAdolescente) {
          this.council.elderlyFund = null;
        } else {
          this.council.childrenFund = null;
        }
      },
      deep: true
    }
  },
  created() {
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.validationMethod = this.validate;
    if (this.$route.query != null && this.$route.query.councilId != null) {
      this.councilService.find(
        this.findCouncilCallback,
        this.$route.query.councilId
      );
    }

    this.projectService.listAllBank(this.listAllBankCallback);
    this.locationService.listAllState(this.listAllStateCallback);
    this.pageOptions.changeClass("footer");
  },
  methods: {
    searchCEP() {
      if (
        this.council.entity.address.postalCode == "" ||
        this.council.entity.address.postalCode.length < 8
      )
        return false;
      this.loaderInput = true;
      this.locationService
        .searchCEP(this.council.entity.address.postalCode)
        .then(data => {this.searchCEPCallback(data)});
    },
    save() {
      if (this.isValid()) {
        if (this.account && this.digit) {
          this.council.bankData.account = this.account + this.digit;
        }
        this.councilService.save(this.saveCallback, this.council);
      }
    },
    // Callback
    searchCEPCallback(obj) {
      if (!obj.erro) {
        if (obj.street) this.council.entity.address.street = obj.street;
        if (obj.neighborhood) this.council.entity.address.neighborhood = obj.neighborhood;
        this.council.entity.address.city = obj.city;
        this.council.entity.address.state = obj.state;
        this.cepValidation = true;
      } else {
        this.council.entity.address.street = "";
        this.council.entity.address.neighborhood = "";
        this.council.entity.address.city = "";
        this.council.entity.address.state = "";
        this.cepValidation = false;
      }
      this.loaderInput = false;
    },
    findCouncilCallback(data) {
      this.council = data;
      if (this.council.entity == null) this.council.entity = new Entity();
      if (this.council.entity.address == null)
        this.council.entity.address = new Address();
      if (this.council.file == null) this.council.file = new File();

      if (!this.council.bankData) {
        this.council.bankData = new BankData();
      } else {
        let oldStr = this.council.bankData.account;
        this.digit = oldStr.slice(-1);
        this.account = oldStr.slice(0, oldStr.length - 1);
      }
      if (this.council.elderlyFund == null)
        this.council.elderlyFund = new ElderlyFund();
      if (this.council.childrenFund == null)
        this.council.childrenFund = new ChildrenFund();
    },
    listAllStateCallback(data) {
      this.stateList = data;
    },
    listAllBankCallback(data) {
      // Banco do Brasil e Caixa
      let mostPopularBanks = data.filter(
        bank => bank.code == "001" || bank.code == "104"
      );

      let otherBanks = data.filter(
        bank => bank.code != "001" && bank.code != "104"
      );
      
      this.bankList = mostPopularBanks.concat(otherBanks);
    },
    saveCallback(data) {
      this.$router.push({
        name: Routes.app.User,
        query: { entityId: data.entity.id }
      });
    },
    // Validations
    verifyCNPJ() {
      if (this.council.entity.cnpj.length < 14) return false;
      this.entityService
        .findByCNPJAndEntityType(this.council.entity.cnpj, EntityType.Council)
        .then(
          function(data) {
            if (data != null && this.council.entity.id != data.id) {
              this.$store.commit(
                "SET_SNACKBAR_MESSAGE",
                "Já existe um Conselho cadastrado com esse CNPJ"
              );
              this.cnpjUnique = false;
            } else {
              this.cnpjUnique = true;
            }
          }.bind(this)
        );
    },
    validate(performMethods) {
      if (!this.hasPermission([Permissions.CRUDCouncil])) {
        if (
          this.council.file == null ||
          this.council.file.path == null ||
          this.council.file.path == ""
        ) {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Escolha uma imagem para o conselho"
          );
          return false;
        }
        if (!this.cepValidation) {
          this.$store.commit("SET_SNACKBAR_MESSAGE", "CEP Inválido");
          return false;
        }
      }
      if (this.council.entity.address.postalCode != "") {
        if (!this.cepValidation) {
          this.$store.commit("SET_SNACKBAR_MESSAGE", "CEP Inválido");
          return false;
        }
      }
      let valid = this.validateComponents(performMethods);

      if (
        this.council.childrenFund &&
        this.council.childrenFund.cnpj != "" &&
        !CommonHelper.validateCNPJ(this.council.childrenFund.cnpj)
      ) {
        this.$store.commit("SET_SNACKBAR_MESSAGE", "CNPJ do fundo inválido");
        return false;
      }

      if (
        this.council.elderlyFund &&
        this.council.elderlyFund.cnpj != "" &&
        !CommonHelper.validateCNPJ(this.council.elderlyFund.cnpj)
      ) {
        this.$store.commit("SET_SNACKBAR_MESSAGE", "CNPJ do fundo inválido");
        return false;
      }

      if (
        this.council.entity.cnpj != "" &&
        !CommonHelper.validateCNPJ(this.council.entity.cnpj)
      ) {
        this.$store.commit("SET_SNACKBAR_MESSAGE", "CNPJ inválido");
        return false;
      } else if (!this.cnpjUnique) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Já existe um Conselho cadastrado com esse CNPJ"
        );
        return false;
      }

      if (!valid) return false;
      return true;
    },
    validateAgency() {
      if (this.council.bankData.agency) {
        return (
          this.validateIncreasingSequence(this.council.bankData.agency) &&
          this.validateRepeatSequence(this.council.bankData.agency)
        );
      }
    },
    validateAccount() {
      if (this.account) {
        return (
          this.validateIncreasingSequence(this.account) &&
          this.validateRepeatSequence(this.account)
        );
      }
    },
    validateDigit() {
      var regex = /^[\w]{1}$/;
      return regex.test(String(this.digit).toLowerCase());
    },
    validateIncreasingSequence(current) {
      var numbers = "0123456789";
      var numbersRev = "9876543210";
      return (
        numbers.indexOf(String(current)) === -1 &&
        numbersRev.indexOf(String(current)) === -1
      );
    },
    validateRepeatSequence(current) {
      var regex = /^(.)\1+$/;
      return !regex.test(String(current).toLowerCase());
    }
  }
};
</script>
