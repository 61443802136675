export default class ChildrenFund {

    constructor() {

        this.id = 0;
        this.name = "";
        this.active = true;
        this.cnpj = "";
        this.councilId = 0;

    }
}