import Entity from "@/scripts/models/entity.model";
import BankData from "@/scripts/models/bankData.model";
import File from "@/scripts/models/file.model";
import ChildrenFund from "@/scripts/models/childrenFund.model";
import ElderlyFund from "@/scripts/models/elderlyFund.model";

export default class Council{

    constructor(){

        this.id = 0;
        this.entity = new Entity();
        this.incentiveLawId = 0;
        this.file = new File();
        this.bankData = new BankData();
        this.childrenFund = new ChildrenFund();
        this.elderlyFund =  new ElderlyFund();
        this.cnpjdbf = "";

    }
}